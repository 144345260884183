import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[(!_vm.showDetailsModal)?_c('div',[_c(VBtn,{staticClass:"mt-8 ml-8",attrs:{"color":"white","outlined":""},on:{"click":_vm.handleNavigate}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiArrowLeft)+" ")]),_c('span',[_vm._v(" Voltar ")])],1),_c(VCardText,{staticClass:"pt-8 px-0"},[_c('div',{staticClass:"container-header"},[_c('span',[_c('h1',[_vm._v("Esse é o seu Painel de Documentos!")]),_c('p',[_vm._v("Clique no campo desejado para visualizar o documento.")])]),_c('div',{staticClass:"image-content"},[_c(VImg,{staticClass:"overlay-image",attrs:{"src":require("@/assets/images/avatars/10.png"),"max-height":"200","max-width":"200","contain":""}}),_c(VImg,{staticClass:"image-header",attrs:{"src":require("@/assets/images/svg/sqg-document-header.svg"),"max-height":"300","max-width":"200","contain":""}})],1)])]),(_vm.isLoadingDocuments)?_c('div',{staticClass:"d-flex justify-center py-10"},[_c(VProgressCircular,{attrs:{"size":"50","color":"white","indeterminate":""}})],1):_vm._e(),_c(VCard,{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingDocuments),expression:"!isLoadingDocuments"}],staticClass:"cards-container"},_vm._l((_vm.MenuData),function(element,index){return _c('div',{key:index,staticClass:"card"},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v(" "+_vm._s(element.icon)+" ")]),_c('h1',[_vm._v(_vm._s(element.title))]),_c('p',[_vm._v(_vm._s(element.subtitle))]),_c(VBtn,{attrs:{"color":"purple","width":"130px","height":"30px","outlined":""},on:{"click":function($event){return _vm.filterDocumentByType(element.title)}}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" VISUALIZAR ")])])],1)}),0)],1):_vm._e(),(_vm.showDetailsModal)?_c('TableOfDocuments',{attrs:{"documents":_vm.filteredDocuments,"document-type":_vm.documentType},on:{"close-window":function($event){_vm.showDetailsModal = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }